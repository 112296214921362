<template>
  <div>
    <!-- 轮播图 -->
    <el-carousel :interval="2000" height="400px">
      <el-carousel-item v-for="(item, index) in carouselList" :key="index">
        <el-image
          style="width: 100%; height: 400px"
          :src="require(`../img/banner1.jpg`)"
          :fit="contain"
        ></el-image>
        <div class="text-over-image">
          <div class="message">
            {{ item.message }}
          </div>
          <div class="centertext">
            {{ item.message1 }}
          </div>
          <div class="input-container">
            <input type="text" placeholder="请输入您的手机号" />
            <button type="button">获取方案</button>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- card -->
    <div style="display: flex">
      <el-card
        style="flex: 1"
        shadow="hover"
        v-for="(item, index) in imglist"
        :key="index"
      >
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <div style="flex: 1">
            <img
              :src="require(`../img/home-business${index + 1}.png`)"
              alt=""
              class="carouselElementImg"
            />
          </div>
          <div style="flex: 5; text-align: center">
            <div>{{ item.name }}</div>
            <div style="color: #999999; margin-top: 1rem">
              {{ item.message }}
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 文本 -->
    <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        我们能帮您做什么
      </div>
      <div style="text-align: center; margin-top: 1%; color: #999999">
        硬件端+应用端+加工生产，从产品研发到落地生产，全程覆盖跟踪
      </div>
    </div>
    <!-- 产品 -->
    <div>
      <div v-for="(element, num) in HardwareObj" :key="num">
        <el-divider content-position="left">
          <div style="display: flex; align-items: center">
            <img
              :src="require(`../img/home-solution-${element.num}.png`)"
              alt="硬件端开发图标"
              style="margin-right: 10px"
            />
            <span style="font-size: 19px; font-weight: 600; color: red">{{
              element.title
            }}</span>
          </div>
        </el-divider>
        <div class="grid-container">
          <el-card
            style="background-color: #f2f5f8; position: relative"
            v-for="(item, index) in element.Hardwarelist"
            :key="index"
          >
            <div style="font-weight: 500">{{ item.name }}</div>
            <div
              style="
                color: white;
                font-size: 70px;
                position: absolute;
                right: 0;
                top: 0;
              "
            >
              0{{ index + 1 }}
            </div>

            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <ul style="flex: 4; padding: 0">
                <li v-for="(event, i) in item.message" :key="i">
                  {{ event }}
                </li>
              </ul>
              <div style="flex: 1">
                <img
                  :src="require(`../img/Hardware${item.Render}.png`)"
                  alt=""
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div v-for="(element, num) in HardwareObj1" :key="num">
        <el-divider content-position="left">
          <div style="display: flex; align-items: center">
            <img
              :src="require(`../img/home-solution-${element.num}.png`)"
              alt="硬件端开发图标"
              style="margin-right: 10px"
            />
            <span style="font-size: 19px; font-weight: 600; color: red">{{
              element.title
            }}</span>
          </div>
        </el-divider>
        <div class="grid-container1">
          <el-card
            style="background-color: #f2f5f8; position: relative"
            v-for="(item, index) in element.Hardwarelist"
            :key="index"
          >
            <div style="font-weight: 500">{{ item.name }}</div>
            <div
              style="
                color: white;
                font-size: 70px;
                position: absolute;
                right: 0;
                top: 0;
              "
            >
              0{{ index + 1 }}
            </div>

            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <ul style="flex: 4; padding: 0">
                <li v-for="(event, i) in item.message" :key="i">
                  {{ event }}
                </li>
              </ul>
              <div style="flex: 1">
                <img
                  :src="require(`../img/Hardware${item.Render}.png`)"
                  alt=""
                  style="width: 5rem; height: 5rem"
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div v-for="(element, num) in HardwareObj2" :key="num">
        <el-divider content-position="left">
          <div style="display: flex; align-items: center">
            <img
              :src="require(`../img/home-solution-${element.num}.png`)"
              alt="硬件端开发图标"
              style="margin-right: 10px"
            />
            <span style="font-size: 19px; font-weight: 600; color: red">{{
              element.title
            }}</span>
          </div>
        </el-divider>
        <div class="grid-container1">
          <el-card
            style="background-color: #f2f5f8; position: relative"
            v-for="(item, index) in element.Hardwarelist"
            :key="index"
          >
            <div style="font-weight: 500">{{ item.name }}</div>
            <div
              style="
                color: white;
                font-size: 70px;
                position: absolute;
                right: 0;
                top: 0;
              "
            >
              0{{ index + 1 }}
            </div>

            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <ul style="flex: 4; padding: 0">
                <li v-for="(event, i) in item.message" :key="i">
                  {{ event }}
                </li>
              </ul>
              <div style="flex: 1">
                <img
                  :src="require(`../img/Hardware${item.Render}.png`)"
                  alt=""
                  style="width: 5rem; height: 5rem"
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <!-- 联系 -->
    <div>
      <el-card style="margin-top: 2rem">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div style="flex: 1; text-align: center">
            <div>
              <span style="font-size: 30px; color: red; font-weight: 600"
                >康斯联科</span
              >
              <span style="color: #999999; font-weight: 600"
                >物联网科技有限公司</span
              >
            </div>
            <div style="font-weight: 600; margin-top: 1rem">
              没有找到您需要的解决方法？
            </div>
          </div>
          <div style="flex: 1; text-align: center">
            <i
              class="el-icon-phone-outline"
              style="font-size: 1.5rem; margin-top: 0.8rem"
              >客户经理</i
            >
            <div
              style="
                font-size: 20px;
                font-weight: 600;
                color: red;
                margin-top: 1rem;
              "
            >
              158-6970-0070
            </div>
          </div>
          <div style="flex: 1; text-align: center">
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <img
                src="../img/winxin.png"
                alt=""
                style="width: 5rem; height: 5rem"
              />
              <div class="vertical-text">立马添加专属顾问</div>
              <div class="vertical-text">获取免费解决方案</div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 文本 -->
    <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        为什么要选择我们
      </div>
      <div style="text-align: center; margin-top: 1%; color: #999999">
        从“软件”到“硬件”，团队覆盖产业链上下游，经验丰富，保障项目落地的可靠性
      </div>
    </div>
    <!-- 动态图 -->
    <div style="margin: 5rem 0 5rem 0">
      <div style="display: flex">
        <div style="flex: 1; position: relative">
          <div>
            <div
              style="position: absolute; top: -1%; right: -23%; font-size: 20px"
            >
              两大研发中心
            </div>
            <div
              style="position: absolute; top: 5%; right: -23%; color: #999999"
            >
              济南”与“深圳”两地一线研发团队，标准化项目实施流程
            </div>
          </div>
          <div>
            <div
              style="position: absolute; top: 47%; right: -3%; font-size: 20px"
            >
              拥有众多自主技术
            </div>
            <div
              style="position: absolute; top: 53%; right: -3%; color: #999999"
            >
              自主研发的通信技术，定位技术以及云感技术等，研发实力强
            </div>
          </div>
          <div>
            <div
              style="position: absolute; top: 95%; right: -23%; font-size: 20px"
            >
              企业配套全
            </div>
            <div
              style="position: absolute; top: 101%; right: -23%; color: #999999"
            >
              项目软硬件设计+研发+生产，打造完整供应链资源，省心又放心
            </div>
          </div>
        </div>
        <div style="flex: 1; position: relative">
          <img src="../doimg/zero_img10.png" alt="" style="" />
          <div v-for="(item, index) in 9" :key="index">
            <img
              :src="require(`../doimg/zero_img${index + 1}.png`)"
              alt=""
              style="
                position: absolute;
                width: 400px;
                height: 400px;
                top: -20px;
                right: 100px;
              "
              :class="shouldSpinAndBlink(index)"
            />
          </div>
        </div>
        <div style="flex: 1; position: relative">
          <div>
            <div
              style="position: absolute; font-size: 20px; top: -3%; left: -20%"
            >
              擅长物联网通信
            </div>
            <div
              style="position: absolute; color: #999999; top: 3%; left: -20%"
            >
              擅长基于GPRS、WIFI、NB-Iot等通讯方式的数据传输，产品稳定不掉线
            </div>
          </div>
          <div>
            <div
              style="position: absolute; font-size: 20px; top: 47%; left: -5%"
            >
              比同行价格更优惠
            </div>
            <div
              style="position: absolute; color: #999999; top: 53%; left: -5%"
            >
              熟识行业前沿技术，丰富一线项目经验，价格更有竞争力
            </div>
          </div>
          <div>
            <div
              style="position: absolute; font-size: 20px; top: 95%; left: -20%"
            >
              完善售后服务保障
            </div>
            <div
              style="position: absolute; color: #999999; top: 101%; left: -20%"
            >
              7*24小时全方面服务支持，一对一项目组+评价机制，让您合作无忧
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 文本 -->
    <!-- <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        案例中心
      </div>
      <div style="text-align: center; margin-top: 1%; color: #999999">
        客户之托，完美呈现，全力打造每一个行业精品
      </div>
    </div> -->
    <!-- 案例轮播 -->
    <!-- <div style="margin-top: 5rem">
      <el-carousel :interval="4000" type="card" height="500px">
        <el-carousel-item v-for="item in casestudy" :key="item.num">
          <el-image
            :src="require(`../img/casestudy${item.num}.png`)"
            style="width: 700px; height: 500px"
          ></el-image>
          <div style="position: absolute; top: 90%; left: 45%">
            <el-link>{{ item.name }}</el-link>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <!-- 文本切换 -->
    <!-- <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        新闻中心
      </div>
      <div style="text-align: center; margin-top: 2%">
        <el-radio-group v-model="radio1">
          <el-radio-button label="公司新闻"></el-radio-button>
          <el-radio-button label="行业动态"></el-radio-button>
        </el-radio-group>
      </div>
      <div style="display: flex; margin-top: 1%">
        <div style="flex: 2">
          <div>
            <el-card>
              <div class="carousel">
                <div
                  class="slides"
                  :style="{ transform: `translateX(${-currentIndex * 100}%)` }"
                >
                  <div v-for="slide in slides" :key="slide.num" class="slide">
                    这里可以放置轮播图内容 
                    <img
                      :src="require(`../img/newmessage${slide.num}.jpg`)"
                      alt=""
                    />
                    <div style="position: absolute;top: 10%;left: 50%;">
                      <div style="color: red;">{{ slide.name }}</div>

                      <div>{{ slide.date }}</div>

                      <div>{{ slide.message }}</div>
                    </div>
                  </div>
                </div>
                <div class="controls">
                  <el-link @click="prev" :underline="false"
                    ><i class="el-icon-caret-left"></i
                  ></el-link>
                  <el-link @click="next" :underline="false"
                    ><i class="el-icon-caret-right"></i
                  ></el-link>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div style="flex: 1">
          <el-card></el-card>
        </div>
      </div>
    </div> -->
  </div>
</template>
  <script>
export default {
  name: "DashBoard",
  data() {
    return {
      imglist: [
        { name: "全自主开发", message: "软件+硬件全自主开发 一线项目经验丰富" },
        { name: "株洲+深圳", message: "两大研发中心 产品品质保障性强" },
        {
          name: "工业设计及模具设计",
          message: "丰富的成品设计落地经验 可帮客户完成整机研发",
        },
        {
          name: "生产加工",
          message: "全自主SMT产线，组装线 可配套完成客户的生产",
        },
      ],

      carouselList: [
        {
          message: "软件+硬件结合开发！",
          message1: " 软硬件结合开发项目经验丰富，擅长软硬件间通讯",
          num: 1,
        },
        {
          message: "从“研发”到“落地生产” 全自主化解决",
          message1:
            "从研发方案，到产品小批量测试，至落地转产，全产业链帮您解决！",
          num: 2,
        },
        {
          message: "硬件云平台私有化部署，解除您的后顾之忧！",
          message1:
            " 优秀的技术架构，高并发支持，随需动态扩展，支持移动端操作，使用更便捷",
          num: 3,
        },
      ],
      HardwareObj: [
        {
          title: "硬件端开发",
          num: 1,
          Hardwarelist: [
            {
              name: "共享类硬件开发",
              message: [
                "共享产品智能硬件开发",
                "配套APP/小程序开发",
                "共享设备控制板开发",
                "基于您需求的定制研发",
              ],
              Render: 1,
            },
            {
              name: "远程控制类开发",
              message: [
                "远通断电开发",
                "远程信号控制开发",
                "远程音视频通信开发",
                "模块级产品开发",
              ],
              Render: 2,
            },
            {
              name: "Android主板开发",
              message: [
                "Android硬件主板开发",
                "Android系统级嵌入式开发",
                "应用开发",
                "上位机控制系统开发",
              ],
              Render: 3,
            },
            {
              name: "LINUX主板开发",
              message: [
                "linux主控板开发",
                "边缘计算机开发",
                "基于linux平台的应用软件开发",
                "下游控制开发",
              ],
              Render: 3,
            },
            {
              name: "远程数据采集开发",
              message: [
                "数据采集项目定制开发",
                "数采盒子开发",
                "硬件协议对接",
                "毫秒级通信能力",
              ],
              Render: 3,
            },
            {
              name: "设备主控板开发",
              message: [
                "机器主板开发",
                "单片机控制板开发",
                "传感器开发",
                "智能硬件主板开发",
              ],
              Render: 3,
            },
            {
              name: "智能硬件整机开发生产",
              message: [
                "智能硬件电路设计",
                "智能硬件配套软件开发",
                "外壳设计制造",
                "智能硬件生产",
              ],
              Render: 3,
            },
            {
              name: "机器人设计研发",
              message: [
                "工业机器人设计开发",
                "水下机器人设计开发",
                "机器人主控板开发生产",
                "管道机器人设计开发",
              ],
              Render: 3,
            },
          ],
        },
      ],
      HardwareObj1: [
        {
          title: "软件开发",
          num: 2,
          Hardwarelist: [
            {
              name: "云平台软件开发",
              message: [
                "云平台软件开发",
                "硬件数据中台开发",
                "通信平台开发",
                "大数据平台开发",
              ],
              Render: 4,
            },
            {
              name: "手机端开发(APP/小程序)",
              message: [
                "物联网APP开发",
                "物联网小程序开发",
                "用户操作端开发",
                "基于工控屏的软件开发",
              ],
              Render: 5,
            },
            {
              name: "上位机软件开发",
              message: [
                "基于Win系列系统的上位机开发",
                "Android或Linux系统的上位机开发",
                "控制逻辑开发",
                "配套通信开发",
              ],
              Render: 5,
            },
            {
              name: "触摸屏软件开发",
              message: [
                "基于工控屏的控制软件开发",
                "触摸屏驱动开发",
                "控制逻辑开发",
                "基于ARM系统的软件开发",
              ],
              Render: 5,
            },
            {
              name: "数据大屏软件开发",
              message: [
                "云端大数据展示平台开发",
                "配套数据采集开发",
                "配套传感器系统开发",
                "数据多样化展示开发",
              ],
              Render: 5,
            },
            {
              name: "数字孪生开发",
              message: [
                "建筑数字孪生系统开发",
                "智慧园区开发",
                "数字平台配套三维建模",
                "基于传感器数据的实时展示开发",
              ],
              Render: 5,
            },
          ],
        },
      ],
      HardwareObj2: [
        {
          title: "加工生产",
          num: 3,
          Hardwarelist: [
            {
              name: "外壳设计及制造",
              message: ["独立的工业设计部门", "十年以上的外壳设计经验"],
              Render: 1,
            },
            {
              name: "整机组装加工",
              message: ["整机组装产线", "严格产品测试"],
              Render: 2,
            },
            {
              name: "SMT贴片加工",
              message: ["多层板加工生产", "自主SMT产线"],
              Render: 3,
            },
          ],
        },
      ],
      specialIndices: "spinning",
      specialIndices: "blinking",
      casestudy: [
        { name: "超市购物车锁", num: 1 },
        { name: "仓库安全管理系统", num: 2 },
        { name: "空气净化器控制系统", num: 3 },
        { name: "太阳能储能项目", num: 4 },
        { name: "加油机税控采集终端", num: 5 },
        { name: "共享单车锁", num: 6 },
        { name: "驱鸟器管理", num: 7 },
      ],
      radio1: "公司新闻",
      slides: [
        {
          num: 1,
          name: "联NB-IoT智能门磁 - 疫情下的守护卫士",
          date: "2022.11.29",
          message:
            "——目前鉴于“新冠病毒”的传染性，很多地区采取隔离措施，有的地方会对病例的所在的整栋楼、整个小区进行隔离观察。而要实现高效、安全的隔离检查，必要的技术手段是必不可少的。所以，基于NB-IoT的智能门磁...",
        },
        {
          num: 2,
          name: "软硬件开发-专业的物联网技术服务商",
          date: "2022.11.29",
          message:
            "——物联网是什么？物联网即“万物相连的互联网”，简单来说就是让所有能行使独立功能的普通物体实现互联互通的网络。什么是物联网解决方案？物联网覆盖生活的各个领域，物联网智慧家居、智慧交通、智慧教育、智慧医...",
        },
      ],
      newmessage1: [
        {
          num: 3,
          name: "工业控制DTU功能及在实际生产中的应用",
          date: "2022.02.26",
          message:
            "——工业级DTU是一款用于远程数据传输的一款传输设备，是物联网无线数据终端设备的一种，既可传输视频、图片大文件，也可传输监测数据等小文件。工业级DTU用途：可使用各大无线运营商提供的各种无线网络，利用...",
        },
        {
          num: 4,
          name: "物联网技术带动了各行各业之间的“通信”的变革",
          date: "2022.02.26",
          message:
            "——物联网技术发展至今，已经触及到生活的方方面面，我们之前的很多“道听途说”目前已经纷纷变为了现实：智能手环、智能监控、智慧安防、智能家居、智能设备改造、大屏数据可视化管理等等，这些智能化硬件的连接让...",
        },
      ],
      currentIndex: 0,
    };
  },

  methods: {
    shouldSpinAndBlink(index) {
      console.log(index);
      const listshan = [2, 6, 7, 8];
      const listxuan = [1, 3, 5];
      if (listshan.includes(index + 1)) {
        return this.specialIndices1;
      }
      if (listxuan.includes(index + 1)) {
        return this.specialIndices;
      }
      if (index + 1 === 10) {
        return this.specialIndices2;
      }
    },
    prev() {
      this.currentIndex =
        (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
  },
  mounted() {},
};
</script>
  
<style lang="less" scoped>
.img {
  position: relative;
}

.text-over-image {
  width: 25rem;
  position: absolute;
  top: 50%; /* 调整垂直位置 */
  left: 50%; /* 调整水平位置 */
  transform: translate(-50%, -50%);
  color: white;
}

.input-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 100px;
  margin-top: 50px;
}

.input-container input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 8px 12px;
  height: 30px;
}

.input-container button {
  border: none;
  background-color: skyblue;
  color: white;
  cursor: pointer;
  height: 48px;
}

.centertext {
  margin-top: 50px;
}

.message {
  font-size: 50px;
  font-weight: 600;
  width: 40rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 创建两列，每列占1fr单位 */
  grid-gap: 2%; /* 列之间的间隙，可根据需要调整 */
  justify-content: space-between; /* 可选，根据情况调整水平间距 */
}

.grid-container1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建两列，每列占1fr单位 */
  grid-gap: 2%; /* 列之间的间隙，可根据需要调整 */
  justify-content: space-between; /* 可选，根据情况调整水平间距 */
}

ul {
  list-style-type: none; /* 移除默认的列表样式 */
}

ul li {
  position: relative; /* 为伪元素定位提供容器 */
  padding: 0 0 2% 0;
  color: #999999;
  font-size: 13px;
}

ul li:before {
  content: "•"; /* 使用特殊字符作为自定义列表标记 */
  color: red; /* 设置颜色为红色 */
  display: inline-block; /* 使伪元素像一个独立的元素显示 */
  margin-right: 5px; /* 给列表项文本添加一点间距 */
}

.spinning {
  animation: rotateImage 4s linear infinite; /* 旋转动画无限循环 */
}

.blinking {
  animation: blinkImage 1s step-end infinite; /* 闪烁动画无限循环 */
  opacity: 0; /* 初始不透明度设为0 */
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blinkImage {
  0%,
  49% {
    opacity: 0;
  }
  50%,
  100% {
    opacity: 1;
  }
}

.vertical-text {
  /* 设置文本垂直排列，从右向左 */
  writing-mode: vertical-rl;
  font-size: 12px;
  color: red;
}

.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.slides {
  display: flex;
  transition: transform 0.5s ease;
}
.slide {
  flex: 0 0 100%;
  position: relative;
}
.controls {
  position: absolute;
  bottom: 10px;
  left: 45%;
  transform: translateX(-50%);
}

.image-container {
  width: 100%;
  height: 400px;
  overflow: hidden; /* 隐藏超出的部分 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container img {
  object-fit: contain; /* 或者使用 'contain' 根据你的需求 */
  max-width: none; /* 确保图片不受父元素宽度限制 */
  max-height: none; /* 确保图片不受父元素高度限制 */
}
</style>  