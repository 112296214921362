<template>
  <div>123</div>
</template>
  
  <script>
export default {
  name: "CsSolutionDep",
  data() {
    return {};
  },

  methods: {},
};
</script>
  
    <style lang="less" scoped>
</style>  