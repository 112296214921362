<template>
  <el-container>
    <el-header>
      <div style="display: flex">
        <div class="head1">
          <img
            src="../img/logo.jpg"
            alt=""
            class="headlog"
            style="height: 4rem"
          />
        </div>
        <div class="head2">
          <el-menu
            :default-active="activeIndex2"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            background-color="white"
            text-color="black"
            active-text-color="red"
            router
            style="font-weight: 600; display: flex"
          >
            <el-menu-item index="/DashBoard" class="navfontsize">
              首页
            </el-menu-item>
            <el-menu-item index="/SoftwareDevelopment" class="navfontsize">
              软件开发
            </el-menu-item>

            <el-submenu index="/InternetDevelopment" class="navfontsize">
              <template slot="title">
                <span class="navfontsize">物联网开发</span>
              </template>
              <el-menu-item index="/InternetDevelopment"
                >软件+硬件开发</el-menu-item
              >
              <el-menu-item index="2-1">云服务开发</el-menu-item>
              <el-menu-item index="2-1">工业与磨具设计</el-menu-item>
              <el-menu-item index="2-1">生产加工</el-menu-item>
            </el-submenu>

            <el-menu-item index="/ApplicationCase" class="navfontsize">
              应用案例
            </el-menu-item>

            <el-submenu index="3" class="navfontsize">
              <template slot="title">
                <span class="navfontsize">解决方案</span>
              </template>

              <el-menu-item index="/CsSolution">共享产品</el-menu-item>
              <el-menu-item index="3-1">智能硬件</el-menu-item>
              <el-menu-item index="3-2">工厂智能化</el-menu-item>
              <el-menu-item index="3-3">机器人开发</el-menu-item>
              <el-menu-item index="3-4">安卓主板</el-menu-item>
            </el-submenu>

            <el-menu-item index="/ContactUs" class="navfontsize"
              >联系我们</el-menu-item
            >
            <el-menu-item index="/AboutCs" class="navfontsize"
              >关于我们</el-menu-item
            >
          </el-menu>
        </div>
        <div class="head3">
          <div class="temnumber">
            <i
              class="el-icon-phone-outline"
              style="font-size: 1.5rem; margin-top: 0.8rem"
              >158-6970-0070</i
            >
          </div>
        </div>
      </div>
    </el-header>
    <el-main>
      <div style="display: flex">
        <div style="flex: 1"></div>
        <div style="flex: 5"><router-view /></div>
        <div style="flex: 1"></div>
      </div>
    </el-main>
    <el-footer style="background-color: #303030; height: 10rem">
      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          color: white;
        "
      >
        <div
          v-for="item in footerList"
          :key="item.num"
          style="flex: 1; display: flex; margin: 1%"
        >
          <div>
            <img :src="require(`../img/footer_${item.num}.png`)" alt="" />
          </div>
          <div style="line-height: 1.5rem; margin-left: 2%">
            <div>{{ item.name }}</div>
            <div>{{ item.message }}</div>
          </div>
        </div>
      </div>
      <el-divider content-position="left"></el-divider>
      <div style="text-align: center">
        株洲康斯联科物联网科技公司 版权所有
        <a href="https://beian.miit.gov.cn/">湘ICP备2023010131号</a>
      </div>
    </el-footer>
  </el-container>
</template>
  <script>
export default {
  name: "CssolutionIndex",
  data() {
    return {
      dds: "123",
      activeIndex2: "/DashBoard",
      seachmessage: "",
      timer: null,
      i: true,
      footerList: [
        { name: "质量保证", message: "强大技术支持，全程严格监控", num: 1 },
        { name: "时效保障", message: "高品质按时交付", num: 3 },
        { name: "售后保障", message: "全方位售后服务", num: 4 },
        { name: "服务保证", message: "7*24小时多渠道服务支持", num: 2 },
      ],
    };
  },
  mounted() {
    this.$router.push("/DashBoard");
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },

    test() {
      console.log(this.$route);
      console.log(this.$router);
    },
  },
  beforeDestroy() {
    this.timer = null;
  },
};
</script>
<style lang="less">
.router-link {
  text-decoration: none;
}
.router-link-active {
  text-decoration: none;
  color: skyblue;
}

.navfontsize {
  flex: 1;
  font-size: 1rem !important;
  text-align: center;
}

.temnumber {
  background-color: red;
  border: 1px solid red;
  border-radius: 100px;
  width: 100%;
  text-align: center;
  height: 3rem;
}
.headlog {
  width: 100%;
}
.head1 {
  flex: 1;
}
.head2 {
  flex: 5;
}
.head3 {
  flex: 1;
}
</style>  