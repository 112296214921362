<template>
  <div>
    <!-- 顶部 -->
    <div style="position: relative; height: 310px">
      <img
        src="./SoftwareDevelopmentimg/SoftwareDevelopmentIndex.jpg"
        alt=""
        style="width: 146.5%; position: absolute; left: -25%"
      />
      <div style="position: absolute; top: 20%; font-size: 30px; color: white">
        全行业软件定制开发
      </div>
      <div style="position: absolute; top: 35%; font-size: 20px; color: white">
        10年软件开发经验，全程项目团队为您一对一金牌服务
      </div>

      <el-card style="position: absolute; top: 30%; right: 0%; width: 20rem">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div style="flex: 1"><i class="el-icon-chat-line-round"></i></div>
          <div style="flex: 2">在线咨询:</div>
          <el-button type="danger" size="small" style="flex: 4"
            >点击咨询</el-button
          >
        </div>
        <div style="display: flex">
          <div style="flex: 1"><i class="el-icon-phone-outline"></i></div>
          <div style="flex: 2">联系电话:</div>
          <span style="flex: 4; color: red">158-6970-0070</span>
        </div>
        <div style="display: flex">
          <div style="flex: 1"><i class="el-icon-message"></i></div>
          <div style="flex: 2">企业邮箱:</div>
          <span style="flex: 4; color: red">724222516@QQ.com</span>
        </div>
      </el-card>
    </div>
    <!-- 步骤 -->
    <div>
      <el-card style="width: 100%">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <div
            v-for="(item, index) in imglist"
            :key="item.name"
            style="flex: 1"
          >
            <div style="text-align: center">
              <img :src="item.img" alt="" />
            </div>
            <div style="text-align: center; margin-top: 2%; font-size: 20px">
              {{ item.name }}
            </div>
            <div
              style="
                text-align: center;
                color: #8f8e8e;
                font-size: 15px;
                margin-top: 2%;
              "
            >
              {{ item.message }}
            </div>
            <div style="text-align: center; margin-top: 2%">
              <div
                style="
                  margin-left: 43%;
                  background-color: #f6f8fa;
                  border-radius: 50px;
                  width: 25px;
                  color: red;
                "
              >
                {{ index }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 文本 -->
    <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        我们提供的服务
      </div>
      <div style="text-align: center; margin-top: 1%; color: #999999">
        无法找到对应服务，请阁下留步，我们为您一对一定制
      </div>
    </div>
    <!-- 服务 -->
    <div style="display: flex">
      <el-card
        shadow="hover"
        v-for="item in ServiceList"
        :key="item.name"
        style="flex: 1; margin: 1%"
      >
        <div style="text-align: center"><img :src="item.img" alt="" /></div>
        <div style="text-align: center; font-size: 25px">{{ item.name }}</div>
        <div
          style="
            text-align: center;
            color: #999999;
            font-size: 15px;
            margin-top: 2%;
          "
        >
          {{ item.message }}
        </div>
        <div
          style="
            text-align: center;
            color: #999999;
            font-size: 15px;
            margin-top: 2%;
          "
        >
          {{ item.message1 }}
        </div>
        <div
          style="
            text-align: center;
            color: #999999;
            font-size: 15px;
            margin-top: 2%;
          "
        >
          {{ item.message2 }}
        </div>
        <div
          style="
            text-align: center;
            color: #999999;
            font-size: 15px;
            margin-top: 2%;
          "
        >
          <el-button type="danger">立即咨询</el-button>
        </div>
      </el-card>
    </div>
    <!-- 文本 -->
    <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        客户案例
      </div>
      <div style="text-align: center; margin-top: 1%; color: #999999">
        全自主研发生产，技术支持力度强
      </div>
    </div>
    <!-- 客户案例 -->
    <el-row :gutter="12">
      <el-col
        :xs="24"
        :sm="12"
        :md="8"
        v-for="item in CustomerList"
        :key="item.img"
      >
        <el-card shadow="hover">
          <el-tag type="danger">{{ item.name }}</el-tag>
          <img :src="item.img" alt="" style="width: 100%; margin-top: 1%" />
          <el-link :underline="false" class="ellipsis">
            {{ item.message }}
          </el-link>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
  
  <script>
import serviceIcon04 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/serviceIcon04.png";
import serviceIcon05 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/serviceIcon05.png";
import serviceIcon06 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/serviceIcon06.png";
import serviceIcon07 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/serviceIcon07.png";
import serviceIcon08 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/serviceIcon08.png";
import serviceIcon09 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/serviceIcon09.png";

import software_icon1 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/software_icon1.png";
import software_icon2 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/software_icon2.png";
import software_icon3 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/software_icon3.png";
import software_icon4 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/software_icon4.png";

import H6110UG32 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H6110UG32.jpg";
import H6111I4364 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H6111I4364.jpg";
import H6112HA35 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H6112HA35.jpg";
import H611140J57 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H611140J57.jpg";
import H611220HV from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H611220HV.jpg";
import H6111153216 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H6111153216.jpg";
import H6112346261 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H6112346261.jpg";
import H6112551435 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220H6112551435.jpg";
import P3095554911 from "@/views/SoftwareDevelopment/SoftwareDevelopmentimg/1-220P3095554911.jpg";

export default {
  name: "SoftwareDevelopment",
  data() {
    const imglist = [
      { name: "前期沟通", message: "线上或电话进行沟通", img: serviceIcon04 },
      { name: "需求确认", message: "项目经理与您确认需求", img: serviceIcon05 },
      { name: "签约付款", message: "确定合同并预付款项", img: serviceIcon06 },
      { name: "项目服务", message: "各部门员工协同服务", img: serviceIcon07 },
      { name: "出品验收", message: "专业人员对产品审核", img: serviceIcon08 },
      { name: "售后服务", message: "产品出厂后期跟踪服务", img: serviceIcon09 },
    ];
    const ServiceList = [
      {
        name: "APP开发",
        message: "超过300例全行业丰富软件开发经验",
        message1: "株洲+深圳资深技术团队",
        message2: "打造一对一品控及金牌服务",
        img: software_icon1,
      },
      {
        name: "微信开发",
        message: "依托于微信的公众号与微信小程序开发",
        message1: "从内容策划到视觉设计",
        message2: "为您打造移动互联时代的获客利器",
        img: software_icon2,
      },
      {
        name: "云服务开发",
        message: "基于智能硬件通信方式",
        message1: "的云服务开发",
        message2: "实现不同场景需求的完整物联网链接",
        img: software_icon3,
      },
      {
        name: "高端网站建设",
        message: "形式模板化的网站打动不了您",
        message1: "也打动不了客户，从“心”塑造您的",
        message2: "网媒品牌形象，让产品脱颖而出",
        img: software_icon4,
      },
    ];
    const CustomerList = [
      {
        name: "电池管理系统",
        message: "为电池使用、管理、监督建立一个统一的后台",
        img: P3095554911,
      },
      {
        name: "2860地域小程序",
        message:
          "2860是一个县域特色社群新零售平台。致力通过“线下社群+线上平台”双通道驱动的创",
        img: H6112HA35,
      },
      {
        name: "爱的礼物APP",
        message:
          "主要以为生日提供各种服务的线上平台，便于你直接在线定鲜花、定蛋糕、定生日贺卡",
        img: H6112551435,
      },
      {
        name: "科普官网",
        message:
          "科普集团创建于2002年，集团本部位于泉城济南，在省内设有青岛、烟台、潍坊、济宁",
        img: H6112346261,
      },
      {
        name: "霸力官网",
        message:
          "济南霸力升降机械有限公司自创立以来,始终以创造一流的升降机械为奋斗目标",
        img: H611220HV,
      },
      {
        name: "智能家居APP",
        message:
          "以住宅为平台，利用综合布线技术、网络通信技术、 安全防范技术、自动控制技术、音视",
        img: H6111I4364,
      },
      {
        name: "欣生然环控小程序",
        message:
          "随着经济的发展和人民生活水平的提高，人们对生活质量和健康的要求愈来愈高",
        img: H611140J57,
      },
      {
        name: "淘药网小程序",
        message:
          "淘药网是一个支持多商家入驻的OTC药品、医疗器械、保健食品交易平台。",
        img: H6111153216,
      },
      {
        name: "贴票票APP",
        message:
          "用户可以在平台进行电影票务的订购、查询相关的服务商家,在线购买相关的文化产品、整合文化类资源分享平台,有效的进行文化传播",
        img: H6110UG32,
      },
    ];
    return {
      imglist,
      ServiceList,
      CustomerList,
    };
  },

  methods: {},
};
</script>
  
<style lang="less" scoped>
.ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>  