<template>
  <div>
    <!-- 背景图片 -->
    <div style="position: relative; height: 310px">
      <img
        src="./ContactUsImg/1-22021515555cQ.jpg"
        alt=""
        style="width: 146.5%; position: absolute; left: -25%"
      />
    </div>

    <!-- 联系 -->
    <div style="margin-top: 8%" class="card-container">
      <div v-for="item in ContactUsList" :key="item.img">
        <el-card shadow="hover">
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div style="flex: 1">
              <img :src="item.img" alt="" />
            </div>
            <div style="flex: 3">
              <div>{{ item.name }}</div>
              <div style="font-size: 14px; color: #999999">
                {{ item.message }}
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>

    <!-- 文本 -->
    <div>
      <div style="text-align: center; font-size: 35px; margin-top: 5%">
        在线留言
      </div>
      <div style="text-align: center; margin-top: 1%; color: #999999">
        MESSAGE BOARO
      </div>
    </div>

    <!-- 留言 -->
    <div class="messgebox">
      <el-input
        style="margin-top: 3%"
        placeholder="请输入您的姓名"
        v-model="value1"
        clearable
      >
      </el-input>
      <el-input
        style="margin-top: 3%"
        placeholder="请输入您的手机号"
        v-model="value2"
        clearable
      >
      </el-input>
      <el-input
        style="margin-top: 3%"
        placeholder="请输入您的公司名称"
        v-model="value3"
        clearable
      >
      </el-input>
      <el-input
        style="margin-top: 3%"
        type="textarea"
        placeholder="请输入您的功能需求"
        v-model="value4"
        autosize
      >
      </el-input>

      <el-button
        style="margin-top: 3%; margin-left: 40%; width: 20%"
        type="danger"
        >提交</el-button
      >
    </div>
  </div>
</template>
  
<script>
import img1 from "@/views/ContactUs/ContactUsImg/contact-phone.png";
import img2 from "@/views/ContactUs/ContactUsImg/contact-tel.png";
import img3 from "@/views/ContactUs/ContactUsImg/contact-email.png";
export default {
  name: "ContactUs",
  data() {
    const value1 = "";
    const value2 = "";
    const value3 = "";
    const value4 = "";
    const ContactUsList = [
      { name: "免费客服电话", message: "客服电话：158-6970-0070", img: img1 },
      { name: "售后服务电话", message: "联系电话：158-6970-0070", img: img2 },
      { name: "邮件留言", message: "724222516@QQ.com", img: img3 },
    ];
    return {
      ContactUsList,
      value1,
      value2,
      value3,
      value4,
    };
  },

  methods: {},
};
</script>
  
<style lang="less" scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* 为了确保每个卡片占据一半的宽度，加上一定的margin或padding */
.card-container > div {
  flex: 0 0 calc(32% - 10px); /* 减去10px是为了给左右两边留出5px的间距 */
  margin: 5px;
}

.messgebox {
  width: 60%;
  margin-left: 20%;
}
.messgebox > messageinput {
  margin-top: 2%;
}
</style>  