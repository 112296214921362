<template>
  <div>
    <!-- 首页 -->
    <div style="position: relative; height: 310px">
      <img
        src="./AboutCsimg/1-220215160120312.jpg"
        alt=""
        style="width: 146.5%; position: absolute; left: -25%"
      />
      <div style="position: absolute; top: 50%; left: 30%; color: white">
        <div style="text-align: center; font-size: 30px; margin-bottom: 1%">
          关于康斯联科
        </div>
        <div>
          软件技术、物联网研发与应用、大数据分析、智能硬件研发、互联网品牌策划
        </div>
      </div>
    </div>
    <!-- card卡片 -->
    <div style="position: relative; height: 410px">
      <el-card shadow="hover" style="position: absolute">
        <div style="display: flex">
          <div style="flex: 1">
            <img src="./AboutCsimg/cs.png" alt="" style="width: 100%" />
          </div>
          <div style="flex: 2; margin-left: 2%; line-height: 2rem">
            <div>
              <span style="font-size: 30px; font-weight: 800">康斯联科</span>
              <span
                >是一家集软件技术、物联网研发与应用、大数据分析、智能硬件研发、互联网品牌策划等高科技项目研发生产于一体的新型智能技术服务商。</span
              >
            </div>
            <div style="margin: 2% 0 2% 0">
              <span style="color: red">•</span>
              <span style="font-weight: 800">软件解决方案</span>
              <div>
                多年来公司致力于行业软件一体化解决方案的开发。专注于移动端软件、传感器应用系统软件、数据可视化系统、品牌全案等方向，公司成立至今已服务超过300个互联网产品案例。
              </div>
            </div>
            <div style="margin: 2% 0 2% 0">
              <span style="color: red">•</span>
              <span style="font-weight: 800">物联网解决方案</span>
              <div>
                公司拥有自主研发中心、独立生产线，具备充足的产业上下游资源，是行内少有的
                可以从 从“想法构思”到“产品批量生产”的一站式物联网服务商。
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 介绍 -->
    <div
      style="
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
      "
    >
      <div style="flex: 1">
        <div>上线项目</div>
        <div style="font-size: 5rem">300+</div>
      </div>
      <div style="flex: 1">
        <div>产品出货</div>
        <div style="font-size: 5rem">50000+</div>
      </div>
      <div style="flex: 1">
        <div>覆盖行业</div>
        <div style="font-size: 5rem">20+</div>
      </div>
    </div>
    <!-- 产品示意图 -->
    <div style="position: relative; height: 410px">
      <div class="img1style"></div>
      <div class="img2style"></div>
      <div class="img3style"></div>
      <el-card
        shadow="hover"
        style="
          background-color: #ef6f6f;
          border-radius: 1rem;
          position: absolute;
          top: 3%;
          left: 45%;
          width: 50%;
          line-height: 2rem;
          color: white;
          padding: 3%;
        "
      >
        <div style="font-size: 30px">我们相信产品和技术能让世界变得更好</div>
        <div style="margin-top: 2%">
          <div>
            超50人一线研发团队，致力于为客户提供从“想法构思”到“批量生产”的一站式专业服务。
          </div>
          <div>
            精准、专业、高效是设计团队奉行的准则，为客户提供优质设计资料。
          </div>
          <div>
            深度发掘市场发展规律，为客户量身定制精准高效的物联网解决方案。
          </div>
          <div>一切以客户满意为中心，为客户创造超预期服务体验。</div>
        </div>
      </el-card>
    </div>
    <!-- da -->
    <div style="height: 15rem"></div>
  </div>
</template>
  
<script>
export default {
  name: "AboutCs",
  data() {
    return {};
  },

  methods: {},
};
</script>
  
    <style lang="less" scoped>
.img1style {
  border-radius: 1rem;
  background-image: url("./AboutCsimg/img1.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 40%;
  height: 15rem;
  margin: 5% 0 5% 2%;
}

.img2style {
  border-radius: 1rem;
  background-image: url("./AboutCsimg/img4.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 80%;
  height: 20rem;
}

.img3style {
  border-radius: 1rem;
  position: absolute;
  top: 90%;
  left: 60%;
  background-image: url("./AboutCsimg/img5.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 80%;
  height: 15rem;
}
</style>  