import Vue from 'vue'
import VueRouter from 'vue-router'
import CssolutionIndex from '../views/CssolutionIndex'
import AboutCs from '../views/AboutCs'
import ContactUs from '../views/ContactUs'
import SoftwareDevelopment from '../views/SoftwareDevelopment'
import InternetDevelopment from '../views/InternetDevelopment'
import ApplicationCase from '../views/ApplicationCase'
import CsSolutionDep from '../views/CsSolutionDep'
import DashBoard from '../views/DashBoard'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'CssolutionIndex',
    component: CssolutionIndex,
    children: [
      {
        path: '/DashBoard',
        name: 'DashBoard',
        component: DashBoard,
        meta: {
          title: '首页',
        }
      }, {
        path: '/SoftwareDevelopment',
        name: 'SoftwareDevelopment',
        component: SoftwareDevelopment,
        meta: {
          title: '软件开发',
        }
      },
      {
        path: '/InternetDevelopment',
        name: 'InternetDevelopment',
        component: InternetDevelopment,
        meta: {
          title: '物联网开发',
        }
      },
      {
        path: '/ApplicationCase',
        name: 'ApplicationCase',
        component: ApplicationCase,
        meta: {
          title: '应用案例',
        }
      },
      {
        path: '/CsSolutionDep',
        name: 'CsSolutionDep',
        component: CsSolutionDep,
        meta: {
          title: '解决方案',
        }
      },
      {
        path: '/ContactUs',
        name: 'ContactUs',
        component: ContactUs,
        meta: {
          title: '联系我们',
        }
      },
      {
        path: '/AboutCs',
        name: 'AboutCs',
        component: AboutCs,
        meta: {
          title: '关于康斯联科',
        }
      }
    ]
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
