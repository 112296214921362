<template>
  <div>
    <!-- 背景图片 -->
    <div style="position: relative; height: 310px">
      <img
        src="./ApplicationCaseimg/1-220215153J5335.jpg"
        alt=""
        style="width: 146.5%; position: absolute; left: -25%"
      />
    </div>
    <!-- 文本 -->
    <div>
      <div style="text-align: center; font-size: 35px; margin-top: 8%">
        推荐案例
      </div>
    </div>
    <!-- 推荐案例  -->
    <div class="card-container">
      <div v-for="item in ApplicationCaseList" :key="item.img">
        <el-card shadow="hover">
          <img
            :src="item.img"
            alt=""
            style="width: 100%; margin-top: 1%; height: 20rem"
          />
          <el-link :underline="false" style="font-size: 20px">
            {{ item.name }}
          </el-link>
          <el-link
            :underline="false"
            style="color: #999999; margin-top: 2%; padding-top: 1%"
          >
            {{ item.message }}
          </el-link>
          <el-divider></el-divider>
          <el-link :underline="false">
            {{ item.message1 }}
          </el-link>
        </el-card>
      </div>
    </div>
  </div>
</template>
  
  <script>
import img1 from "./ApplicationCaseimg/图片2.png";
import img4 from "./ApplicationCaseimg/1-2202241I535154.jpg";
import img10 from "./ApplicationCaseimg/1-2202241FHO06.jpg";
import img12 from "./ApplicationCaseimg/1-2202241J93J18.jpg";
import { ref } from "vue";
export default {
  name: "ApplicationCase",
  data() {
    const ApplicationCaseList = ref([
      {
        name: "智慧渔场项目",
        message:
          "物联网平台能够实时监测水环境的情况，自动分析监测数据，联动控制系统，某物质含量超标可短信报警，避免潜在的经济损失。",
        message1:
          "物联网平台可以整合采购、销售、库存、财务等多个环节，实现生产过程的数字化管理，实现无人值守，提高管理效率和透明度。",
        img: img1,
      },
      {
        name: "仓库安全管理系统",
        message:
          "包括温湿度、精密空调、普通空调、漏水、新风机等环境监控；门禁监控、安防监控、消防监控等",
        message1:
          "开发目的：指针对物品在仓储环节对仓库物品要求、消防要求、报警要求等综合性管理措施",
        img: img4,
      },
      {
        name: "简码鹰眼",
        message:
          "运用云计算、大数据、物联网、无线网络通信、可视化等技术，系统针对异常情况实时预警，从而严格保障电力设备的安全、稳定、高效运行。",
        message1: "开发目的：降低安全隐患发生的可能性，保障系统的高效运行",
        img: img12,
      },
      {
        name: "智慧农场",
        message:
          "利用物联网、人工智能、云计算等技术变革传统农林养殖业，将农业物联网与农业生产技术深度融合，为农场等农业企业客户提供全面的智慧农业解决方案。",
        message1: "开发目的：实现农场智能化管理，减轻人工压力",
        img: img10,
      },
    ]);
    return {
      ApplicationCaseList,
    };
  },

  methods: {},
};
</script>
  
    <style lang="less" scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* 为了确保每个卡片占据一半的宽度，加上一定的margin或padding */
.card-container > div {
  flex: 0 0 calc(50% - 10px); /* 减去10px是为了给左右两边留出5px的间距 */
  margin: 5px;
}
</style>  